import axios_pro from '../axios_pro';
export function getProjectPointList(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post("/netuser/Project/getProjectPointList", params || {})
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}
export function getProjectPointTypeList(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post("/netuser/Project/getProjectPointTypeList", params || {})
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}