import styles from "./index.module.css";
// import qrcode_xcx from "../../resource/xcx.jpg"

const Index = (props) => {
  return (
    <div id="xcx"  style={{}} >
      <div className="container" style={{ padding: "48px" }}>
        <div className="section-title text-center">
          <h2>扫码进小程序</h2>
          <p>扫码进入小程序查看所有酒店项目</p>
        </div>
        <div className={styles.img_content}>
          <img src="img/shangjie2.jpg" className={styles.img} />
        </div>
        {/* <div className={"row visible-xs"}>
        <div className={"col-md-6 " + styles.juzhong}>
          <img src="img/xcx.jpg" className={styles.img} />
        </div>
        <div className={"col-md-6 " + styles.juzhong}>
          扫码进入小程序查看所有酒店项目
        </div>
      </div>
      <div id="xcx" className={"row visible-sm visible-md visible-lg"}>
        <div className={"col-md-6 " + styles.juyou + " " + styles.height}>
          <img src="img/xcx.jpg" className={styles.img} />
        </div>
        <div className={"col-md-6 " + styles.juzuo + " " + styles.height}>
          扫码进入小程序查看所有酒店项目
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default Index;
