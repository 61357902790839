import styles from "./index.module.css";
// import qrcode_xcx from "../../resource/xcx.jpg"
import api from "../../mc/Api";
import { useState, useEffect } from "react";
import { Modal } from "antd";

const Index = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get_data();
  }, []);

  const get_data = () => {
    api.common.getProjectPointTypeList().then((rs) => {
      if (rs.code == 0) {
        setData(rs.data);
      } else {
        Modal.error({
          title: "提示",
          content: "错误代码：point",
        });
      }
    });
  };

  return (
    <div id="fuwu" className={"text-center " + styles.content}>
      <div className="container">
        <div className="section-title">
          <h2>我们的服务</h2>
          <p>点击下方提交您的项目需求，我们将竭诚为您服务</p>
        </div>
        <div>
          {data.map((item) => {
            return (
              <>
                <h3 className={styles.item_title} key={item.id}>
                  {item.name}
                </h3>
                <div className={styles.item_content}>
                  {item.points.map((point) => {
                    return <a className={styles.item} key={point.id} key={point.id} href={point.seo&&point.seo.url} style={{
                      background:!point.seo && "gray"
                    }} >{point.name}</a>;
                  })}
                </div>
              </>
            );
          })}
          {/* <h3 className={styles.item_title}>分类</h3>
          <div className={styles.item_content}>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
          </div>
          <h3 className={styles.item_title}>分类</h3>
          <div className={styles.item_content}>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
            <div className={styles.item}>哈哈哈</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
