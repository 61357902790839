import axios_pro from '../axios_pro';


export function login(params) {
    return new Promise((resolve, reject) => {
        axios_pro.post("/admin/Admin/login", params)
            .then(function (res) {
                resolve(res.data)
            }).catch(function (err) {
                reject(err)
            })
    })
}