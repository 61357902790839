import styles from "./index.module.css";

const Index = (props) => {
  return (
    <div>
      <div className={styles.div}>
        <div>
          <div className={styles.left}>
            Powered by 重庆商界创新科技集团有限公司
          </div>
          <div>
            <a
              href="https://beian.miit.gov.cn"
              target="_blank"
              className={styles.right}
            >
              渝ICP备2021005564号-1
            </a>
          </div>
        </div>
      </div>
      <div className={styles.admin}>
        <a
          href="https://www.shangjieshengtai.com/admin"
          target="_blank"
          className={styles.beian}
        >
          管理入口
        </a>
      </div>
    </div>
  );
};

export default Index;
